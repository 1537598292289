import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const DevenirPartenaire = () => {


    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);



    return(
        <motion.div
            className="container-partenaire container-v2"
            ref={ref}
            variants={container}
            initial="hidden"
            animate={control}
        >
            <motion.h2 variants={item}>Devenir partenaire</motion.h2>
            <motion.p variants={item}>
                Vous souhaitez devenir un partenaire Kotimo ? Besoin de développer la visibilité de vos programmes immobiliers neufs ? On a la solution pour vous. Kotimo, facilitateur de projet pour ses clients et ses partenaires, vous propose la mise en avant de vos programmes logements neufs.
            </motion.p>
            <motion.a variants={container} href="/contact">
                <motion.button variants={item}>Découvrir</motion.button>
            </motion.a>
        </motion.div>
    )
}

export default DevenirPartenaire