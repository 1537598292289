import {useState, useEffect} from "react";

function TestFilter() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState(["nom", "code_postal_ville", "departement", "types_lot", "genre_type_lot", "prix_mini"]);
    const [filterParam, setFilterParam] = useState("All");
    useEffect(() => {
        fetch(
            'https://api.leizee.com/programme', {headers: {
                    'Authorization': 'Basic ' + btoa('groupesge-promotion-agence-dewey:19424bcjt2d1pfs02v9dl6p5h81f4t')
                }})
            .then((res) => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []);



    function search(items) {
        return items?.resultat?.filter((item) => {
        if (item?.departement === filterParam || item?.types_lot === filterParam || item?.genres_types_lot.prix_mini === filterParam) {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            ?.toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }
           else if (filterParam === "All") {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            ?.toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }

        });
    }
    if (error) {
        return (
            <p>
                erreur
            </p>
        );
    } else if (!isLoaded) {
        return <>loading...</>;
    } else {
        return (
            <div className="wrapper">
                <div className="search-wrapper">
                    <label htmlFor="search-form">
                        <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search for..."
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                        />
                        <span className="sr-only">Search countries here</span>
                    </label>
                    <div className="select">
                        <select
                            onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}
                            className="custom-select"
                            aria-label="Filter Countries By Region"
                        >
                            <option value="All">Filtrer par département</option>
                            <option value="Gironde">Gironde</option>
                            <option value="Landes">Landes</option>
                            <option value="Charente-Maritime">Charente-Maritime</option>
                            <option value="Pyrénées-Atlantiques">Pyrénées-Atlantiques</option>
                        </select>
                        <span className="focus"></span>
                    </div>
                    <div className="select_type_lot">
                        <div>
                            <input type="checkbox" value="T1" name="T1" onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}/>
                            <label>T1</label>
                        </div>

                        <div>
                            <input type="checkbox" value="T2, T3" name="T2" onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}/>
                            <label>T2</label>
                        </div>

                        <div>
                            <input type="checkbox" value="T3, T4" name="T3" onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}/>
                            <label>T3</label>
                        </div>

                        <div>
                            <input type="checkbox" value="T4" name="T4"/>
                            <label>T4</label>
                        </div>

                    </div>

                    <div className="rangesliderprice">
                        <label>Prix</label>
                        <input type="range" min="0" max="500000" onChange={(e) => {
                            setFilterParam(e.target.value);
                        }}/>
                    </div>

                </div>
                <ul className="card-grid">
                    {search(items)?.map((item) => (
                        <li>
                            <p>{item.nom}</p>
                            <p>{item.departement}</p>
                            <p>{item.types_lot}</p>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
export {TestFilter}