import {Helmet} from "react-helmet"
import GroupePresentation from "../components/Groupe/GroupePresentation";
import Expertises from "../components/Home/Expertises";
import GroupeValeurs from "../components/Groupe/GroupeValeurs";
import FooterFormContact from "../components/FormContact/FooterFormContact";
import Header from "../components/Header/Header";

const PageAbout = () => {
    return(
        <div
            className={`nc-PageAbout relative`}
            data-nc-id="PageAbout">
            <Helmet>
                <title>Un portail immobilier neuf conçus par des passionnés | Kotimo</title>
                <meta name="description" content="Achetez en toute sérénité et au prix direct promoteur avec Kotimo.fr, votre portail immobilier en Nouvelle Aquitaine."/>
            </Helmet>
            <Header/>

            <div className="cotnainer-v2 container-groupeHeader container">
                <h1>LE GROUPE</h1>
            </div>

            <GroupePresentation/>

            <Expertises/>

            <GroupeValeurs/>

            <div className="container-v2 container-form-groupe">
                <h2>CONTACT</h2>
                <FooterFormContact/>
            </div>
        </div>
    )
}
export default PageAbout