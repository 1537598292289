import {Helmet} from "react-helmet";
import HeaderHomeDeux from "../components/HomeDeux/HeaderHomeDeux";
import Programmes from "../components/Home/Programmes";
import Groupe from "../components/Home/Groupe";
import Expertises from "../components/Home/Expertises";
import SliderFocusRegionaux from "../components/Home/SliderFocusRegionaux";
import {Calculator} from "../components/Home/Calculator";
import ComponentBlog from "../components/Home/ComponentBlog";
import AccompagnementDeux from "../components/HomeDeux/AccompagnementDeux";
import Reassurance from "../components/HomeDeux/Reassurance";
import HeaderDeux from "../components/HeaderDeux/HeaderDeux";
import React from "react";
import NosProgrammes from "../components/Home/NosProgrammes";


const PageHomeDeux = () => {

        return(
            <div className="nc-PageHome relative">

                <Helmet>
                    <title>Investissez sans surprise dans des programmes neufs en Nouvelle-Aquitaine </title>
                    <meta name="description" content="Kotimo vous accompagne dans votre projet pour trouver le bien immobilier neuf qui correspond à toutes vos envies."/>
                </Helmet>
                <HeaderDeux/>

                <HeaderHomeDeux/>


                    <div className="container-v2">

                        <Programmes/>

                        <NosProgrammes/>
                    </div>

                        <Expertises/>
                    <SliderFocusRegionaux/>

                    <Groupe/>





                    <div className="container-v2">
                        <AccompagnementDeux/>

                    </div>

                    <div className="container-reassurance ">
                        <Reassurance/>
                    </div>


                    <div className="margin-bottom">
                        <Calculator/>
                    </div>


                <div className=" blog-home mt-28 px-10 xl:px-32">
                <ComponentBlog/>
                </div>


                </div>

        )
}

export default PageHomeDeux