import React from 'react';
import {Helmet} from "react-helmet";
import { useState, useEffect } from 'react';

import Truncate from "react-text-truncate";
import Header from "../components/Header/Header";
const PageBlog = () => {

    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(8);

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4)
    }

    useEffect(() => {
        fetch(`https://admin-kotimo.herokuapp.com/api/posts?sort=createdAt:desc&populate=*`)
            .then((res) => res.json())
            .then((data) => setItems(data));
    }, [items]);


    if(items.length < 1) return <div className="loader-ville"><p>Chargement ...</p></div>

    return (
        <>
            <Header/>
            <div className="container-prog">
                <Helmet>
                    <title>Toute l’actualité immobilière à découvrir sur le blog Kotimo !</title>
                    <meta name="description" content="Envie d’en savoir plus sur l’actualité de l'immobilier neuf ? Vous êtes au bon endroit. Blog Kotimo, votre projet à vos côtés !"/>
                </Helmet>


                <div className="  container-blogheader">
                    <h1>BLOG</h1>
                    <p>Des questions sur les étapes d’achat d’un VEFA ? Besoin de renseignements sur les prêts immobiliers ? Le top 10 des villes où il fait bon vivre ? Sur le Blog Kotimo, retrouvez toute l’actualité immobilière des achats en neuf. Retrouvez également les dernières tendances en Nouvelle-Aquitaine et les conseils avisés de nos experts immobiliers Kotimo.<br/><br/> Appréhender l’immobilier autrement avec nos spécialistes immo. Vous désirez vendre, acheter ou même les deux ? Aucun problème. Parcourez sur notre blog, toutes les dernières actualités de l’immobilier pour tout comprendre : loi Pinel, achat sur plan, avantages fiscaux, imposition, investissement immobilier neuf… Kotimo vous dit tout !</p>
                </div>


                <div className="blogg container-cards">
                    {items.data?.slice(0, visible).map((item) => (
                        <a href={`/blog/${item.attributes.slug}`}>
                            <div className="container-card">
                                <div className="container-img-component">
                                    <img alt="Batiment représentant le bien" src={item.attributes.photo.data?.attributes.url} />
                                </div>
                                <div className="container-txt-component">
                                    <p className="category-blog">{item.attributes.category.data?.attributes.name}</p>
                                    <h2> {item.attributes.titre}</h2>
                                    <p className="duree-lecture">Durée de lecture: {item.attributes.duree_de_lecture} min</p>
                                    <Truncate line={3}
                                                 element="p"
                                                 TruncateText="..."
                                                 text={item.attributes.Extrait_d_article}/>
                                    <a className="button-v" href={`/blog/${item.attributes.slug}`}>Lire l'article</a>
                                </div>
                            </div>
                        </a>
                    ))}

                </div>
                <div className="button-container"><button onClick={showMoreItems} className="button-g dark:button-g-out">PLUS D'ARTICLES</button></div>

            </div>
        </>
    );
}

export default PageBlog;
