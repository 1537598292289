import {Helmet} from "react-helmet";
import React from "react";
import Header from "../../components/Header/Header";

const DonneesPersonnelles = () => {
    return(
        <>
            <Header/>
            <div className=" container container-form-partenaire">
                <Helmet>
                    <title>KOTIMO - Données Personnelles</title>
                </Helmet>

                <div class="container mentions-leg">
                    <h2>Consentement à l'utilisation de cookies.</h2>
                    <p>Pour le bon fonctionnement de notre site Web, nous utilisons cookies. Afin d'obtenir votre consentement valide pour l'utilisation et le stockage de cookies dans le navigateur que vous utilisez pour accéder à notre site Web et pour documenter correctement ceci, nous utilisons une plateforme de gestion des consentements : CookieFirst. Cette technologie est fournie par Digital Data Solutions BV, Plantage Middenlaan 42a, 1018 DH, Amsterdam, Pays-Bas. Site web <a href='https://cookiefirst.com' title='Cookiefirst page'>: https://cookiefirst.com</a> désigné sous le nom de CookieFirst.</p>
                    <p>Lorsque vous accédez à notre site web, une connexion est établie avec le serveur de CookieFirstpour nous donner la possibilité d'obtenir un consentement valide de votre part pour l'utilisation de certains cookies. CookieFirst stocke ensuite un cookie dans votre navigateur afin de pouvoir activer uniquement les cookies auxquels vous avez consenti et de le documenter correctement. Les données traitées sont stockées jusqu'à l'expiration de la période de stockage prédéfinie ou jusqu'à ce que vous demandiez la suppression des données. Certaines périodes de conservation légales obligatoires peuvent s'appliquer nonobstant ce qui précède.</p>
                    <p>CookieFirst est utilisé pour obtenir le consentement légalement requis pour l'utilisation de cookies. La base juridique pour cela est l'article 6, paragraphe 1, point c), du règlement général sur la protection des données (RGPD).</p>
                    <h2>Accord de traitement des données</h2>
                    <p>Nous avons conclu un accord de traitement des données avec CookieFirst. Il s'agit d'un contrat requis par la loi sur la protection des données, qui garantit que les données des visiteurs de notre site Web ne sont traitées que conformément à nos instructions et dans le respect du GDPR.</p>
                    <h2>Fichiers journaux du serveur</h2>
                    <p>Notre site web et CookieFirst collectent et stockent automatiquement des informations dans ce qu'on appelle des fichiers journaux de serveur, que votre navigateur nous transmet automatiquement. Les données suivantes sont collectées :</p>
                    <ul>
                        <li>Votre statut de consentement ou le retrait du consentement.</li>
                        <li>Votre adresse IP anonyme</li>
                        <li>Des informations sur votre navigateur</li>
                        <li>Informations sur votre appareil</li>
                        <li>La date et l'heure de votre visite sur notre site web</li>
                        <li>L'url de la page web où vous avez enregistré ou mis à jour vos préférences de consentement</li>
                        <li>L'emplacement approximatif de l'utilisateur qui a enregistré ses préférences en matière de consentement.</li>
                        <li>un identifiant unique universel (UUID) du visiteur du site web qui a cliqué sur la bannière cookie.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default DonneesPersonnelles;