import React from "react";
import {Router, Route, Routes, BrowserRouter} from "react-router-dom";
import Home from "./pages/Home";
import PageHomeDeux from "./pages/PageHomeDeux";
import PageProgramme from "./pages/PageProgramme";
import PageSingleProgramme from "./pages/PageSingleProgramme";
import PageProgrammesVille from "./pages/PageProgrammesVille";
import PageAbout from "./pages/PageAbout";
import PageBlog from "./pages/PageBlog";
import PageSingleBlog from "./pages/PageSingleBlog";
import PageContact from "./pages/PageContact";
import PageVideo from "./pages/PageVideo";
import MentionsLegales from "./pages/Legal/MentionsLegales";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import BotFooter from "./components/Footer/BotFooter";
import "./styles/index.scss"
import HttpsRedirect from "react-https-redirect";
import HeaderDeux from "./components/HeaderDeux/HeaderDeux";
import Favicon from "react-favicon";
import {TestFilter} from "./pages/TestFilter";
import PageProgrammes from "./pages/PageProgramme";
import DonneesPersonnelles from "../src/pages/Legal/DonneesPersonnelles"


function App() {

    return (
        <HttpsRedirect>
            <Favicon url="https://res.cloudinary.com/agence-dewey/image/upload/v1667906112/favicon_kotimo_e6dd02e002.png?updated_at=2022-11-08T11:15:12.420Z"/>
        <div className="App">
            <div className="bg-white dark:bg-black dark:text-white text-base white:bg-neutral-900 text-neutral-900 white:text-neutral-200">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route  path="/immobilier-neuf" element={<PageHomeDeux/>}/>
                        <Route path="/programmes" element={<PageProgramme/>}/>
                        <Route path="/programme/:idprogramme" element={<PageSingleProgramme/>}/>
                        <Route exact path="/programmes/:nom_ville" element={<PageProgrammesVille/>}/>
                        <Route path="/le-groupe" element={<PageAbout/>}/>
                        <Route path="/blog" element={<PageBlog/>}/>
                        <Route path="/blog/:slug" element={<PageSingleBlog/>}/>
                        {/*<Route path="/devenir-partenaire" element={<DevenirPartenaire/>}/>*/}
                        <Route path="/contact" element={<PageContact/>}/>
                        <Route path="/video" element={<PageVideo/>}/>
                        <Route path="/mentions-legales" element={<MentionsLegales/>}/>
                        <Route path="/donnees-personnelles" element={<DonneesPersonnelles/>}/>
                        <Route path="/test-filter" element={<TestFilter/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <Footer/>
            <BotFooter/>
        </div>
        </HttpsRedirect>
    );
}

export default App;
