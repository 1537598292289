import React,  { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from "yup";


const Newsletter = () => {


    let [isValid, setIsValid] = useState();

    const form = useRef();

    const formik = useFormik({
        initialValues: {
            email:''
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Adresse email invalide').required('Le champs Adresse email est requis'),
        }),
        onSubmit:( values) => {
            emailjs.send('service_k4jevqm', 'template_tydwgqs', values,  'QoOFImhesk8pf11RX')
                .then(() => {
                    formik.resetForm({email: ''})
                });
            if(!values) {

            }
            else{
                setIsValid('Votre message a bien été envoyé');
            }
        },

    });
    return(
        <div className="container-newsletter container-v2">
            <h2>Inscription newsletter</h2>
            <p>Envie de suivre toute l'actu de l'immobilier neuf en Nouvelle Aquitaine, inscrivez-vous à notre newsletter !</p>
            <form onSubmit={formik.handleSubmit}>
                <input id="email"
                       name="email"
                       type="email"
                       placeholder="Email"
                       onChange={formik.handleChange}
                       value={formik.values.email}/>

                <button className="button-g mt-5" type="submit">ENVOYER</button>

            </form>

            {formik.touched.email && formik.errors.email ? (
                <div className="messageError">{formik.errors.email}</div>
            ) : null}

            <p className=" mt-5 successMessage">{isValid}</p>
        </div>
    )
}

export default Newsletter