import React, { useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Breadcrumbs} from "@mui/material";
import Link from "@mui/material/Link";
import GoogleMapReact from "google-map-react";
import HeaderHome from "../components/Home/HeaderHome";
import Header from "../components/Header/Header";
import {useParams} from "react-router-dom";

import { alpha, styled } from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList, faMap} from "@fortawesome/free-solid-svg-icons";
import {FormattedNumber, IntlProvider} from "react-intl";


const AnyReactComponent = ({  img }) => <img alt="icone du bien sur la carte" className="icone-bien" src={img}/>;

const PageProgrammesVille = () => {

    let {nom_ville} = useParams();

    const slugify = str =>
        str
            .toLowerCase()
            .trim()
            .replace(/ /m, "_")
            .replace(/ "é" /gm, "e");

    // Fetch your restaurants immediately after the component is mounted
    const [programmes, setProgrammes] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState(["nom", "code_postal_ville"]);
    const [filterParam, setFilterParam] = useState("All");
    const [view, setView] = useState("map");
    const prog = (programmes.resultat);
    useEffect(() => {
        fetch(`https://api.leizee.com/programme?nom_ville=${nom_ville}`, {headers: {
                'Authorization': 'Basic ' + btoa('kotimo-agence-dewey:i00a1n41wrf3zxn1b347y5d87gz5f9'),
                'Mode': 'no-cors',
            }})
            .then((res) => res.json())
            .then((data) => setProgrammes(data));
    }, [programmes]);

    function search(items) {
        return items?.resultat?.filter((item) => {
            if (item?.departement === filterParam || item?.types_lot == filterParam || item?.genres_types_lot?.prix_mini === filterParam ) {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            ?.toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }
            else if (filterParam === "All") {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            ?.toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            }

        });
    }


    const uniqueIds = [];

    const employees = (programmes.resultat)

    const listVille = employees?.filter(element => {
        const isDuplicate = uniqueIds.includes(element?.ville);

        if (!isDuplicate) {
            uniqueIds.push(element?.ville);

            return true;
        }

        return false;
    });




    if(programmes.length < 1) return <div className="loader-ville"><p>Chargement ...</p></div>





    return (
        <div>
            <Helmet>
                <title>KOTIMO - PAR VILLE</title>
            </Helmet>


            {/*<ComingSoonComp/>*/}
            <Header/>


            <div className="container-v2 container-programmes-all">
                <Breadcrumbs className="fil-ariane" separator=">" aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Accueil
                    </Link>

                    <Link underline="hover" color="inherit" href="/programmes">
                        Programmes
                    </Link>

                    <Link underline="always" color="inherit">
                        {programmes?.resultat[0]?.ville}
                    </Link>
                </Breadcrumbs>
                <div className="txt-programmes-all">
                    <h1>Programmes immobiliers neufs disponibles a {programmes.resultat[0].ville}</h1>
                    <p>Acheter ou investir dans un programme immobilier neuf avec Kotimo. Facilitateur de projet, nous
                        vous accompagnons pour trouver le bien immobilier qui correspond à vos besoins. Lancez-vous dans
                        l’acquisition d’un logement neuf avec Kotimo.fr, votre projet à vos côtés ! </p>
                </div>

                <div className="container-filtering">
                    <div className="search-wrapper">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="search-input"
                                placeholder="Rechercher par nom de bien, ville, departement, ..."
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                            />
                            <span className="sr-only">Search countries here</span>
                        </label>

                        {/*<div className="select">
                            <select
                                onChange={(e) => {
                                    setFilterParam(e.target.value);
                                }}
                                className="custom-select"
                                aria-label="Filter Countries By Region"
                            >
                                <option value="All">Tous les départements</option>
                                {uniqueEmployees?.map(programme => (
                                    <option value={programme?.departement}>{programme.departement}</option>
                                ))}
                            </select>
                            <span className="focus"></span>
                        </div>
                            <div className="select_type_lot">
                            <div>
                            <input type="checkbox" value="T1" name="T1" onChange={(e) => {
                            setFilterParam(e.target.value);
                        }}/>
                            <label>T1</label>
                            </div>

                            <div>
                            <input type="checkbox" value="T2, T3" name="T2" onChange={(e) => {
                            setFilterParam(e.target.value);
                        }}/>
                            <label>T2</label>
                            </div>

                            <div>
                            <input type="checkbox" value="T3" name="T3" onChange={(e) => {
                            setFilterParam(e.target.value);
                        }}/>
                            <label>T3</label>
                            </div>

                            <div>
                                <input type="checkbox" value="T4" name="T4"/>
                                <label>T4</label>
                            </div>

                        </div>

                        <div className="rangesliderprice">
                            <label>Prix</label>
                            <input type="range" min="0" max="500000" onChange={(e) => {
                                setFilterParam(e.target.value);
                            }}/>
                        </div>
*/}
                    </div>
                </div>

                <div className="flex justify-end">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ cursor: 'pointer', paddingRight: '10px' }} onClick={() => setView('list')}>
                            <FontAwesomeIcon icon={faList} style={{ color: view === 'list' ? 'darkviolet' : 'gray' }} />
                        </div>
                        <div style={{ width: '50px', height: '24px', borderRadius: '12px', backgroundColor: 'lightgray' }}>
                            <div onClick={() => setView(view === 'map' ? 'list' : 'map')}  style={{ cursor: 'pointer', width: '24px', height: '24px', borderRadius: '12px', backgroundColor: 'white', transform: view === 'map' ? 'translateX(26px)' : 'translateX(0)' }} />
                        </div>
                        <div style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => setView('map')}>
                            <FontAwesomeIcon icon={faMap} style={{ color: view === 'map' ? 'darkviolet' : 'gray' }} />
                        </div>
                    </div>
                </div>

                {view === 'map' ? (
                    <div className="container-map-programmes">
                        <div className="list-btn">
                            <div className='container-list-gradient'>
                        <ul>
                            {search(programmes)?.map(programme => (
                                <a key={programme.id} className="card-pg" href={`/programme/${programme.id}`}>
                                    <div key={programme.id} className="container-programme">
                                        <div>
                                            <img alt="batietn principal du programme" className="img-programme" src={programme.url_vue_principale.length > 1 ? programme.url_vue_principale : require('../images/icone-remplacement-programme.png') }/>
                                        </div>

                                        <div className="container-content-programme">
                                            <h2>{programme.nom}</h2>
                                            <p className="txt-prix">À partir de <IntlProvider locale='fr'>
                                                <FormattedNumber value={prog.length <= 1 ? programme?.tranches[0]?.lots[0]?.prix[0]?.valeur : programme?.genres_types_lot[0].prix_maxi}/></IntlProvider>€</p>
                                            <div className="container-type-nb">
                                                <p className="txt-type">{programme.genres_lot}</p>
                                                <p className="txt-nb">{programme.nb_lots_total} bien{programme.nb_lots_total < 2 || 's'} dont {programme.nb_lots_dispos} disponible{programme.nb_lots_dispos < 2 || 's'}</p>
                                            </div>
                                            <p className="txt-zone">{programme.code_postal} {programme.ville}</p>
                                            <a href={`/programme/${programme.id}`}>Découvrir</a>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </ul>

                                <div className="gradient"></div>

                            </div>
                            <div className="flex justify-center mt-20">
                                <a className="button-v" href="mailto:commercial@kotimo.fr">Accéder a tout notre catalogue</a>

                            </div>
                        </div>

                        <div className="container-only-map">
                            <GoogleMapReact
                                bootstrapURLKeys={{key: 'AIzaSyBQ0K5lK8sik10StKkSed5tVWKMm0u4wLg'}}
                                defaultZoom={10}
                                defaultCenter={{lat: 44.837789, lng: -0.57918}}
                            >
                                {search(programmes)?.map(programme => (
                                    <AnyReactComponent
                                        lat={programme.latitude}
                                        lng={programme.longitude}
                                        img={require('../images/icone-bien.png')}
                                    />
                                ))}
                            </GoogleMapReact>
                        </div>
                    </div>
                ) : (
                    <div className="programmes">
                        <ul>
                            {search(programmes)?.map(programme => (
                                <a className="card-pg" href={`/programme/${programme.id}`}>
                                    <div key={programme.id} className="container-programme">
                                        <div>
                                            <img alt="Batiment du programme" className="img-programme" src={programme.url_vue_principale.length > 1 ? programme.url_vue_principale : require('../images/icone-remplacement-programme.png') }/>
                                        </div>

                                        <div className="container-content-programme">
                                            <h2>{programme.nom}</h2>
                                            <p className="txt-prix">À partir de {programme?.genres_types_lot[0].prix_mini} €</p>

                                            <div className="container-type-nb">
                                                <p className="txt-type">{programme.genres_lot}</p>
                                                <p className="txt-nb">{programme.nb_lots_total} bien{programme.nb_lots_total < 2 || 's'} dont {programme.nb_lots_dispos} disponible{programme.nb_lots_dispos < 2 || 's'}</p>
                                            </div>
                                            <p className="txt-zone">{programme.code_postal} {programme.ville}</p>
                                            <a href={`/programme/${programme.id}`}>Découvrir</a>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </ul>
                    </div>
                )}

                {/*<div className="container-filter-ville">
                    <div className="title">
                        <h2>Rechercher par ville</h2>
                    </div>
                    <div className="list-ville">

                        {listVille?.map(liste => (
                            <p><a className="button-v"
                                  href={`/programmes/` + slugify(`${liste.ville}`)}>{liste.ville}</a></p>
                        ))}

                    </div>
                </div>*/}
            </div>
        </div>
    )
}

export default PageProgrammesVille;
