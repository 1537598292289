import {motion} from "framer-motion"

const Accompagnement = () => {
    return(
        <div className="container-accompagnement">
            <div className="container-txt-accompagnement">
                <h2>Un accompagnement <br/> sur-mesure</h2>
                <p className="my-6">
                    Acheter ou investir ? Il peut être parfois difficile de se lancer et avoir toutes les bonnes informations. Mais une chose est sûre : c’est que Kotimo sera toujours à vos côtés pour vous accompagner dans cette grande étape. Nos experts immobiliers seront à votre entière disposition pour répondre à toutes vos questions mais également définir toutes les limites et variables de votre projet. De A à Z, n’ayez plus peur de vous lancer seul et soyez accompagné par des passionnés de l’immobilier ! Plus qu’à vous laissez guider, Kotimo.fr s’occupe du reste.
                </p>
                    <a className="button-v" href={"/video"} targetblank>DÉCOUVRIR</a>
            </div>
            <div className="container-image">
                <img alt="Famille a table" src={ require('../../images/accompagnement-home.jpg') } />
            </div>
        </div>
    )
}

export default Accompagnement