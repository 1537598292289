import {Helmet} from "react-helmet";
import React from "react";
import Header from "../../components/Header/Header";

const MentionsLegales = () => {
    return(
        <>
            <Header/>
            <div className=" container container-form-partenaire">
                <Helmet>
                    <title>KOTIMO - Mentions légales</title>
                </Helmet>

                <div class="container mentions-leg">
                    <h1>INFORMATIONS LÉGALES</h1>
                    <p>Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site les informations suivantes :<br/>
                        Société : <strong>SARL TRANSAC 3G</strong><br/>
                        Adresse : <strong>10 avenue de l’Église Romane, 33370 ARTIGUES-PRÈS-BORDEAUX</strong><br/>
                        Tél. : <strong>05 56 32 08 70</strong><br/>
                        Au capital de : <strong>10 000€</strong> <br/>
                        SIRET : <strong>522315076</strong> – voir accueil <br/>
                        R.C.S. : <strong>Bordeaux B 517 736 195</strong> voir accueil <br/>
                        Numéro de TVA intracommunautaire : <strong>FR74 517736195</strong> <br/>
                        Adresse de courrier électronique : <strong><a href="mailto:communication@kotimo.fr">communication@kotimo.fr</a></strong> <br/>
                        Le responsable de la publication, <strong>BRUNO LE CROM</strong> est une <strong>personne morale</strong>. Contactez le responsable de la publication : <a href="mailto:communication@kotimo.fr">communication@kotimo.fr</a><br/>
                        Le créateur du site est : Dewey<br/>
                        Le webdesigner : Paul Groleaud<br/>
                        L’hébergeur du site est : <strong>OVH, 2 rue Kellermann, 59100 ROUBAIX</strong><br/>
                        Tél. : <strong>08 20 69 87 65</strong><br/>
                        Éventuellement modifiables, nous vous invitons donc à consulter nos mentions légales le plus souvent possible, de manière à en prendre connaissance fréquemment.</p>
                    <h2>CONDITIONS GÉNÉRALES D'UTILISATION</h2>
                    <p>En utilisant notre site web <a href="https://kotimo.fr">www.kotimo.fr</a>, vous acceptez pleinement et entièrement les conditions générales d’utilisation précisées dans nos mentions légales. Accessible à tout type de visiteurs, il est important de préciser toutefois qu’une interruption pour maintenance du site web peut-être décidée par Kotimo. Les dates et heures d’interruptions seront néanmoins précisées à l’avance aux utilisateurs.</p>
                    <h2>LES PRODUITS OU SRVICES PROPOSÉS</h2>
                    <p>En accord avec sa politique de communication, le site <a hreef="https://kotimo.fr">www.kotimo.fr</a> a pour vocation d’informer les utilisateurs sur les services proposés par Kotimo, qui s’efforce alors de fournir des informations précises sur son activité. Cependant, des inexactitudes ou des omissions peuvent exister : la société ne pourra en aucun cas être tenue pour responsable pour toute erreur présente sur le site <a href="https://kotimo.fr">www.groupesge.fr.</a></p>
                    <h2>LIMITATIONS CONTRACTUELLES</h2>
                    <p>Les informations retranscrites sur notre site web <a href="https://kotimo.fr">www.kotimo.fr</a> font l’objet de démarches qualitatives, en vue de nous assurer de leur fiabilité. Cependant, nous ne pourrons encourir de responsabilités en cas d’inexactitudes techniques lors de nos explications.Si vous constatez une erreur concernant des informations que nous auront pu omettre, n’hésitez pas à nous le signaler par mail à <a href="mailto:communication@kotimo.fr">communication@kotimo.fr</a>. Les liens reliés directement ou indirectement à notre site web <a href="https://kotimo.fr">www.kotimo.fr</a> ne sont pas sous le contrôle de notre société. Par conséquent, nous ne pouvons nous assurer de l’exactitude des informations présentes sur ces autres sites Internet.Utilisant la technologie JavaScript, le site <a href="https://kotimo.fr">www.kotimo.fr</a> ne pourra être tenu pour responsable en cas de dommages matériels liés à son utilisation. Par ailleurs, toute autre type de conséquence liée à l’exploitation du site <a href="https://kotimo.fr">www.kotimo.fr</a>, qu’elle soit directe ou indirecte (bug, incompatibilité, virus, perte de marché, etc.).</p>
                    <h2>PROPRIÉTÉ INTELLECTUELLE</h2>
                    <p>Le contenu rédactionnel du site web <a href="https://kotimo.fr">www.kotimo.fr</a> appartient exclusivement à Transac 3G. Toute violation des droits d’auteur est sanctionnée par l’article L.335-2 du Code de la Propriété Intellectuelle, avec une peine encourue de 2 ans d’emprisonnement et de 150 000 € d’amende.Le site <a href="https://kotimo.fr">www.kotimo.fr</a> ne pourra être mis en cause en cas de propos injurieux, à caractère raciste, diffamant ou pornographique, échangés sur les espaces interactifs. La société se réserve également le droit de supprimer tout contenu contraire aux valeurs de l’entreprise ou à la législation applicable en France.En naviguant sur le site <a hre="https://kotimo.fr">www.kotimo.fr</a>, l’utilisateur accepte également l’installation de cookies éventuelle sur son ordinateur.</p>
                    <h2>DONNÉES PERSONNELLES, RESPECT DE VOTRE VIE PRIVÉE</h2>
                    <p>Toute informations recueillie sur le site web <a href="https://kotimo.fr">www.kotimo.fr</a> se font dans le cadre des besoins liés à l’utilisation de notre plateforme, tels que le formulaire de commande ou la demande d’inscription à la Newsletter. Par ailleurs, le désabonnement de la Newsletter se fait grâce au lien situé en pied de page. Kotimo s’engage à ne céder en aucun cas les informations concernant les utilisateurs du site Internet, de quelque façon qu’il soit (vente, échange, prêt, location, don). Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, l’utilisateur bénéficie d’un droit d’accès et de rectification aux informations le concernant, droit qu’il peut exercer à tout moment en adressant un mail à l’adresse <strong><a href="mailto:communication@kotimo.fr">communication@kotimo.fr</a></strong> ou en effectuant sa demande par courrier, à l’adresse suivante : 10 avenue de l’Église Romane, 33370 Artigues-près-Bordeaux.</p>
                    <h2>DROIT APPLICABLE ET LOIS CONCERNÉES</h2>
                    <p>Soumis au droit français, le site web <a href="https://kotimo.fr">www.kotimo.fr</a> est encadré par la loi n° 2004-2005 du 21 juin 2004 pour la confiance dans l’économie numérique, l’article L.335-2 du Code de la Propriété Intellectuelle et la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004.</p>
                </div>
            </div>
        </>
    )
}

export default MentionsLegales;