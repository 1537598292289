import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {brands} from "@fortawesome/fontawesome-svg-core/import.macro";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons"


const SocialsList = () => {
    return (
        <nav
            className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300`}
            data-nc-id="SocialsList">
                    <div className="container-icons-rs">

                        <a href="https://www.facebook.com/Kotimofr" target="_blank">
                        <FontAwesomeIcon icon={brands('facebook-square')} />
                        </a>

                        <a href="https://www.instagram.com/kotimofr/?hl=fr" target="_blank">
                        <FontAwesomeIcon icon={brands('instagram-square')} />
                        </a>

                        <a href="https://www.linkedin.com/company/kotimo/" target="_blank">
                            <FontAwesomeIcon icon={brands('linkedin')} />
                        </a>

                    </div>
        </nav>
    );
};

export default SocialsList;

