const HeaderHome = () => {
    return(
        <div className="container-headerhome">
            <div className="container-content-headerhome">
                <h1>VOTRE PROJET A VOS COTÉS</h1>
                <p className=" txt-header">À la recherche d’un bien immobilier neuf en Nouvelle Aquitaine ? Et si nous vous disions qu’en quelques clics, vous trouviez la maison ou l’appartement de vos rêves. Avec Kotimo, votre projet à vos côtés, trouvez le bien immobilier neuf qui correspond à toutes vos envies.</p>
                <div className="container-button-header sm:mt-5"> <a href="/video" className=" button-g">MON PROJET</a> </div>
            </div>
        </div>
    )
}

export default HeaderHome