import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import Logo from "../Logo/Logo";
import {XIcon} from "@heroicons/react/solid";
import SocialsList from "../SocialsList/SocialsList";
import {NavLink} from "react-router-dom";

const MenuBar = () => {
    const [isVisable, setIsVisable] = useState(false);

    useEffect(() => {
        setIsVisable(false);
    }, [window.location.pathname]);

    const handleOpenMenu = () => {
        {
            setIsVisable(true);

        }
    }
    const handleCloseMenu = () => setIsVisable(false);

    const renderContent = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={handleCloseMenu}
                >
                    <div className="fixed left-0 top-0 bottom-0 w-full md:w-auto z-max outline-none focus:outline-none">
                        <React.Fragment>
                            <Transition.Child
                                as={Fragment}
                                enter="transition duration-100 transform"
                                enterFrom="opacity-0 -translate-x-14"
                                enterTo="opacity-100 translate-x-0"
                                leave="transition duration-150 transform"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="opacity-0 -translate-x-14"
                            >
                                <div className="z-10 relative">
                                    <div className="w-full bg-white  h-screen p-5">
                                        <div className="flex justify-between">
                                            <Logo/>
                                            <div>
                                                <button onClick={handleCloseMenu} className="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700">
                                                    <XIcon className="w-5 h-5" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="border my-5"></div>
                                        <div className="container-social-dark"><SocialsList/>{/*<SwitchDarkMode/>*/}</div>
                                        <div className="border my-5"></div>
                                        <ul className="nc-Navigation lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
                                            <li className="mt-5">
                                                <NavLink to="/programmes" className=" py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 uppercase" activeClassName="is-active">Nos Programmes</NavLink>
                                            </li>
                                            <li className="mt-5">
                                                <NavLink onClick={handleCloseMenu} to="/le-groupe" className="dark:text-dark py-5 text-sm sm:text-base sm:px-6 sm:py-5 uppercase is-active">
                                                    le groupe
                                                </NavLink>
                                            </li>
                                            <li className="mt-5">
                                                <NavLink onClick={handleCloseMenu} to="/blog" className="dark:text-dark py-5 text-sm sm:text-base sm:px-6 sm:py-5 uppercase is-active">
                                                blog
                                            </NavLink>
                                            </li>
                                            <li class="mt-5">
                                                <a className="button-v" href="/video">MON PROJET</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter=" duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave=" duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
                            </Transition.Child>
                        </React.Fragment>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    return (
        <>
            <button
                onClick={handleOpenMenu}
                className="p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="Menu_Icon" data-name="Menu Icon" transform="translate(-330.596 -24.143)">
                        <circle id="Ellipse_27" data-name="Ellipse 27" cx="3" cy="3" r="3" transform="translate(346.596 24.143)" />
                        <circle id="Ellipse_32" data-name="Ellipse 32" cx="3" cy="3" r="3" transform="translate(338.596 24.143)"/>
                        <circle id="Ellipse_35" data-name="Ellipse 35" cx="3" cy="3" r="3" transform="translate(330.596 24.143)"/>
                        <circle id="Ellipse_28" data-name="Ellipse 28" cx="3" cy="3" r="3" transform="translate(346.596 32.143)"/>
                        <circle id="Ellipse_31" data-name="Ellipse 31" cx="3" cy="3" r="3" transform="translate(338.596 32.143)"/>
                        <circle id="Ellipse_34" data-name="Ellipse 34" cx="3" cy="3" r="3" transform="translate(330.596 32.143)"/>
                        <circle id="Ellipse_29" data-name="Ellipse 29" cx="3" cy="3" r="3" transform="translate(346.596 40.143)"/>
                        <circle id="Ellipse_30" data-name="Ellipse 30" cx="3" cy="3" r="3" transform="translate(338.596 40.143)"/>
                        <circle id="Ellipse_33" data-name="Ellipse 33" cx="3" cy="3" r="3" transform="translate(330.596 40.143)"/>
                    </g>
                </svg>
            </button>
            {renderContent()}
        </>
    );
};

export default MenuBar;