import React from "react";

const Expertises = () => {
    return(
        <div className=" container-v2 container-expertises">

            <div className="container-expertise">
                <div className="expertise-single">
                    <h2 className="expertises-title">NOS EXPERTISES</h2>
                </div>

                <div className="expertise-single">
                    <span className="counter-expert">01</span>
                    <p> Des conseils sur-mesure dans l'immobilier neuf en Nouvelle-Aquitaine.</p>
                </div>

                <div className="expertise-single">
                    <span className="counter-expert">02</span>
                    <p>Des conseillers rigoureux et à votre écoute passionnés par l'immmobilier.</p>
                </div>

                <div className="expertise-single">
                    <span className="counter-expert">03</span>
                    <p>Un accompagnement de A a Z pour voir naître votre projet achat de bien neuf.</p>
                </div>

                <div className="expertise-single">
                    <span className="counter-expert"> 04</span>
                    <p>Des garanties et des services fiables par des experts immobiliers.</p>
                </div>
            </div>
        </div>
    )
}

export default Expertises;