import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

function Groupe() {

    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };
    const btn = {
        hidden: {opacity: 0},
        visible: {
            opacity: 1
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);



    return (
        <motion.div
            className="container-groupe container-v2 dark:bg-black"
            ref={ref}
            variants={container}
            initial="hidden"
            animate={control}
        >
            <motion.div variants={container} className="container-img-groupe">
                <motion.img variants={item} src={ require('../../images/bg-team-groupe.jpg') } />
            </motion.div>
            <motion.div variants={container} className="container-txt-groupe">
                <motion.h2 variants={item} className="title-content">LE GROUPE</motion.h2>
                <motion.p variants={item} className="txt-content" style={{marginBottom: '24px'}}>
                    Une entreprise à taille humaine qui a pour seul objectif de trouver le bien immobilier neuf qui correspond à tous vos critères : Kotimo ! Un accompagnement sur-mesure pour votre acquisition. Pensé et réfléchi pour accompagner ses clients dans l’achat de votre futur bien, Kotimo se base sur la forte expérience humaine de ces dirigeants. Mais le groupe Kotimo, c’est avant tout une entreprise créée par des passionnés de l’immobilier. À l’écoute et proche de vous, ils sauront vous accompagner dans votre achat et investissement dans le neuf.
                </motion.p>
                    <a className="button-g-out" href="/le-groupe"> DÉCOUVRIR </a>
            </motion.div>
        </motion.div>
    )
}

export default Groupe;