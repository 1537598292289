const HeaderHome = () => {
    return(
        <div className="container-headerhome">
            <div className="container-content-headerhomedeux">
                <h1>INVESTISSEZ SANS SURPRISE DANS DES PROGRAMMES NEUFS EN NOUVELLE-AQUITAINE</h1>
                <p className=" txt-header">Kotimo vous accompagne dans votre projet pour trouver le bien immobilier neuf qui correspond à toutes vos envies.</p>
                <div className="container-button-header mx-auto mt-5"> <a href="/video" className=" button-g">MON PROJET</a> </div>
            </div>
        </div>
    )
}

export default HeaderHome