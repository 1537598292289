import React,  { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useFormik } from 'formik';
import * as Yup from "yup";

const FormContact = ()=> {

    let [isValid, setIsValid] = useState();

    const form = useRef();

    const formik = useFormik({
        initialValues: {
            email:'',
            nom:'',
            prenom:'',
            telephone: '',
            message: '',
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Le champs Nom est requis'),
            prenom: Yup.string()
                .max(20, 'Must be 20 characters or less')
                .required('Le champs Prénom est requis'),
            telephone: Yup.string()
                .max(10, 'Le champs "Téléphone", doit faire 10 caractères'),
            email: Yup.string().email('Adresse email invalide').required('Le champs Adresse email est requis'),
        }),
        onSubmit:( values) => {
            emailjs.send('service_k4jevqm', 'template_tydwgqs', values,  'QoOFImhesk8pf11RX')
                .then(() => {
                    formik.resetForm({email: '', nom: '', prenom:'', telephone: '', message:''})
                });
            if(!values) {

            }
            else{
                setIsValid('Votre message a bien été envoyé');
            }
        },

    });


    {/*
        emailjs.sendForm('service_k4jevqm', 'template_tydwgqs', form.current, 'QoOFImhesk8pf11RX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    */}


    return (
        <div className="container-form-contact-component">
            <form onSubmit={formik.handleSubmit}>
                <div className="container-nom-prenom-part">
                    <div className="inputs-np">
                <input id="nom"
                       name="nom"
                       type="text"
                       placeholder="Nom"
                       onChange={formik.handleChange}
                       value={formik.values.nom}/>
                {formik.touched.nom && formik.errors.nom ? (
                    <div className="messageError">{formik.errors.nom}</div>
                ) : null}
                    </div>

                <div className="inputs-np">
                <input id="prenom"
                       name="prenom"
                       type="text"
                       placeholder="Prénom"
                       onChange={formik.handleChange}
                       value={formik.values.prenom}/>
                {formik.touched.prenom && formik.errors.prenom ? (
                    <div className="messageError">{formik.errors.prenom}</div>
                ) : null}
                </div>
                </div>
                    <input id="telephone"
                           name="telephone"
                           type="tel"
                           pattern="[0-9]{10}"
                           placeholder="Téléphone"
                           onChange={formik.handleChange}
                           value={formik.values.telephone}/>
                    {formik.touched.telephone && formik.errors.telephone ? (
                        <div className="messageError">{formik.errors.telephone}</div>
                    ) : null}

                <input id="email"
                       name="email"
                       type="email"
                       placeholder="Email"
                       onChange={formik.handleChange}
                       value={formik.values.email}/>
                {formik.touched.email && formik.errors.email ? (
                    <div className="messageError">{formik.errors.email}</div>
                ) : null}

                <textarea  onChange={formik.handleChange}
                           value={formik.values.message}
                            name="message"
                            id="message"
                           placeholder="Message"/>

                <button className="button-g mt-5" type="submit">ENVOYER</button>
                <p className=" mt-5 successMessage">{isValid}</p>

            </form>

            <div className="separator">
                <p>ou</p>
            </div>

            <div className="button-call">
                <a className="button-g mt-6" href="tel:+33629365682">
                    Nous appeler
                </a>
            </div>
        </div>

    )
}

export default FormContact