import {NavLink} from "react-router-dom";

const Navigation = () => {
    return(
        <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
            <li>
                <NavLink to="/programmes" className="px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 uppercase" activeClassName="is-active">
        Nos Programmes
      </NavLink>
            </li>
            <li>
                <NavLink to="/le-groupe" className="px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 uppercase is-active">
                    le groupe
                </NavLink>
            </li>
            <li><NavLink to="/blog" className="px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 uppercase is-active">
                blog
            </NavLink>
            </li>
        </ul>
    )
}

export default Navigation