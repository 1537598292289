import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

    const SliderFocusRegionaux = () =>{

        const slugify = str =>
            str
                .toLowerCase()
                .trim()
                .replace(/ /m, "_")
                .replace(/ "é" /gm, "e");


        // Fetch your restaurants immediately after the component is mounted
        const [programmes, setProgrammes] = useState([]);
        useEffect(() => {
            fetch('https://api.leizee.com/programme', {headers: {
                    'Authorization': 'Basic ' + btoa('kotimo-agence-dewey:i00a1n41wrf3zxn1b347y5d87gz5f9'),
                    'Mode': 'no-cors',
                }})
                .then((res) => res.json())
                .then((data) => setProgrammes(data));
        }, [programmes]);

        const container = {
            hidden: { opacity: 0},
            visible: {
                opacity: 1,
                transition: {
                    delayChildren: 0.3,
                    staggerChildren: 0.2
                }
            }
        };

        const card = {
            hidden: { y: 20, opacity: 0 },
            visible: {
                y: 0,
                opacity: 1
            }
        };

        const control = useAnimation()
        const [ref, inView] = useInView()
        useEffect(() => {
            if (inView) {

                control.start("visible");
            }
            else{
                control.start("hidden");
            }
        }, [control, inView]);


        const uniqueIds = [];

        const employees = (programmes.resultat)

        const listVille = employees?.filter(element => {
            const isDuplicate = uniqueIds.includes(element?.ville);

            if (!isDuplicate) {
                uniqueIds.push(element?.ville);

                return true;
            }

            return false;
        });


        return (
        <div

            className={`nc-SectionSliderNewCategories container-v2 container-focus`}>
            <div className={` flow-root`}>
                <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
                    <div className={"text-left w-full mx-auto"}>
                        <div>
                        <h2 className={`text-md md:text-xl font-semibold`} >
                            FOCUS RÉGIONAUX
                        </h2>
                            <span className="dark:text-white xl:w-8/12 sm:w-full mt-2 md:mt-3 font-normal block text-base text-black dark:text-white sm:text-xl">Kotimo, votre partenaire immobilier sera toujours à vos côtés. Retrouvez chez nous une large offre de logements neufs en Nouvelle Aquitaine.</span>
                        </div>
                        </div>
                </div>
                <div className="container-slider-fr">
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={30}
                        pagination={false}
                        modules={[Pagination]}
                        classname="slider">
                        {listVille?.map( liste => (
                            <SwiperSlide>
                                <a href={`/programmes/` + slugify(`${liste.ville}`)}>
                            <img src={liste.url_vue_principale}/>
                            <h3>{liste.ville}</h3>
                                    <a className="button-v" href={`/programmes/` + slugify(`${liste.ville}`)}>Découvrir</a>
                                </a>
                                </SwiperSlide>
                            ))}
                    </Swiper>
                    <div className="gradient-right"></div>
                </div>
            </div>
        </div>
    );
};

export default SliderFocusRegionaux;


