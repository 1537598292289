import {useState, useEffect} from "react"
import SwitchDarkMode from "../DarkMode/DarkMode";
import LogoDeux from "../Logo/LogoDeux";


const HeaderDeux = () => {

    const [isTop, setisTop] = useState(true);

    useEffect(() => {
        window.onscroll = function () {
            scrollFunction();
        };
    }, []);

    function scrollFunction() {
        const $head = document.getElementById("nc-chisfis-header");
        if (!$head) return;
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            !!isTop && setisTop(false);
        } else {
            setisTop(true);
        }
    }

    return(
        <div
            id="nc-chisfis-header"
            className="nc-Header lg:sticky lg:top-0 w-full lg:left-0 lg:right-0 z-40"
        >

            <div isTop={isTop} >
                <div
                    className={`nc-MainNav1 relative z-10 ${
                        isTop ? "onTop " : "notOnTop  backdrop-filter"
                    }`}
                >
                    <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8 navigation-little">
                        <div className="flex justify-center flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
                            <LogoDeux />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderDeux