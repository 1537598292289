import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import { useEffect} from "react";

const GroupeValeurs = () => {

    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);

    return(
        <motion.div
            ref={ref}
            variants={container}
            initial="hidden"
            animate={control}
            className="container-v2 container-nos-valeur">
            <div className="container-txt-nos-valeur">
                <motion.h2 variants={item}>DES CONSEILLERS A VOTRE ÉCOUTE</motion.h2>
                <motion.p variants={item}>
                    Pour chaque projet, un conseiller ! Avec une connaissance et une maîtrise totale du marché de l’immobilier neuf, il saura vous accompagner de A à Z dans cet achat. À l’écoute, votre conseiller Kotimo saura dissiper vos doutes, répondre à vos interrogations et mettra tout en œuvre pour la recherche de votre solution idéale.<br/><br/> Par ailleurs, Kotimo vous met en relation directement avec des spécialistes de confiance comme des courtiers, des banquiers, des assureurs ou encore des notaires. Entourez-vous de spécialistes du secteur immobilier et allez jusqu’au bout de votre acquisition avec Kotimo !
                </motion.p>
                <motion.div variants={item} className="container-buttons">
                        <a className="button-v" href="/">NOS PROGRAMMES</a>
                </motion.div>
            </div>
            <motion.div variants={container} className="container-image">
                <motion.img variants={item} src={ require('../../images/couple-valeurs.jpg') } />
            </motion.div>
        </motion.div>
    )
}
export default GroupeValeurs