import {Helmet} from "react-helmet";
import HeaderHome from "../components/Home/HeaderHome";
import Programmes from "../components/Home/Programmes";
import Groupe from "../components/Home/Groupe";
import Expertises from "../components/Home/Expertises";
import SliderFocusRegionaux from "../components/Home/SliderFocusRegionaux";
import Accompagnement from "../components/Home/Accompagnement";
import {Calculator} from "../components/Home/Calculator";
import ComponentBlog from "../components/Home/ComponentBlog";
import DevenirPartenaire from "../components/Home/DevenirPartenaire";
import Newsletter from "../components/Home/Newsletter";
import Header from "../components/Header/Header";
import NosProgrammes from "../components/Home/NosProgrammes";

const Home = () => {
    return(
        <div className="nc-PageHome relative">
            <Helmet>
                <title>Trouvez votre bien immobilier neuf en Nouvelle Aquitaine avec Kotimo.fr !</title>
                <meta name="description" content="Kotimo, votre projet à vos côtés. Retrouvez tous les programmes de biens neufs en Nouvelle-Aquitaine. Sécurité & fiabilité."/>
            </Helmet>
            <Header/>

            <div className=" relative space-y-24 lg:space-y-32">
                <HeaderHome/>

                <div className="container-v2">

                    <Programmes/>

                    <NosProgrammes/>
                </div>

                <Groupe/>


                <Expertises/>

                <SliderFocusRegionaux/>

                <div className="container-v2">
                    <Accompagnement/>
                </div>

                <Calculator/>

                <div className=" blog-home mt-28 px-10 xl:px-32">
                    <ComponentBlog/>
                </div>


                <DevenirPartenaire/>

                <Newsletter/>

            </div>
        </div>
    )
}

export default Home;