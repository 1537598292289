import FooterFormContact from "../components/FormContact/FooterFormContact";
import {Helmet} from "react-helmet";
import React from "react";
import Header from "../components/Header/Header";


const PageContact = () => {
    return(
        <>
            <Header/>
            <div className="sm:p-4 sm:py-4 lg:p-24 container container-form-partenaire">
                <Helmet>
                    <title>KOTIMO - Contact</title>
                </Helmet>
                <h1>CONTACTEZ-NOUS</h1>
                <FooterFormContact/>
            </div>
        </>
    )
}

export default PageContact