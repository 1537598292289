import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";


function Programmes() {

    const boxVariant = {
        hidden: {opacity: 0},
        visible: {opacity: 1}
    }

    const hoverButton = {
        initial: {
            boxShadow: "12px 12px 12px rgba(50, 50, 50, 0)"
        },
        hover: {
            boxShadow: "12px 12px 12px rgba(50, 50, 50, 1)"
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);


    return (
        <motion.div
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
            className="container-programes">
            <div className="container-txt-programes">
                <h2 className="dark:text-white title-content">NOS PROGRAMMES</h2>
                <p className="dark:text-white txt-content" style={{marginBottom: '24px'}}>
                    Avec une large sélection, découvrez nos programmes immobiliers neuf en Nouvelle Aquitaine. Bordeaux, Mérignac, Lormont, Villenave D’Ornon ou encore, le Bassin-d’Arcachon, choisissez le bien dans votre ville de coeur. Tous nos programmes proposés sont passés à la loupe par nos experts de l’immobilier. Grâce à Kotimo, simplifiez-vous la vie et menez à bien votre projet d’achat de logement neuf. <br/><br/>Retrouvez sur notre page quelques exemples de logements neufs proposés par Kotimo. Des programmes en centre-ville, à la campagne, à la mer ou à la montagne, faites vous séduire par le programme immobilier neuf qui vous fera chavirer. Besoin d’un conseil ou d’un accompagnement ? Contactez les conseillers Kotimo pour vos questions achat, investissement ou même habitation.
                </p>
                    <a class="button-v" href="/programmes"> Découvrir les programmes </a>

            </div>
            <div className="container-img-programmes">
                <img src={require('../../images/bg-house-programmes.jpg')} />
            </div>
        </motion.div>
    )
}

export default Programmes;