import Logo from "../Logo/Logo";
import SocialsList from "../SocialsList/SocialsList";

const Footer = () => {
    return (
        <div className="nc-Footer relative py-24 lg:py-32">
            <div className="container max-w-full mx-auto grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-4 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2 md:col-span-1">
                        <Logo/>
                    </div>
                    <div>
                        <p className="text-black dark:text-white">Portail immobilier neuf en Nouvelle-Aquitaine. Achetez ou investissez dans le bien immobilier neuf qui vous correspond !</p>
                        <SocialsList/>
                    </div>

                </div>




                <div>
                    <div className="text-sm">
                        <h2 className="font-semibold text-[#3B0F70] dark:text-[#A08155]">
                            Contactez-nous
                        </h2>
                        <ul className="mt-5 space-y-4">
                                <li className="uppercase">
                                    <a
                                        className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="tel:+33629365682">
                                        06 29 36 56 82
                                    </a>
                                </li>
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="mailto:commercial@kotimo.fr">
                                   COMMERCIAL@KOTIMO.FR
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="border-bottom-mobile-footer"></div>
                </div>


                <div>
                    <div className="text-sm">
                        <h2 className="font-semibold text-neutral-700 white:text-neutral-200">
                        </h2>
                        <ul className="mt-5 space-y-4">
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/programmes">
                                    Nos Programmes
                                </a>
                            </li><li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/le-groupe">
                                    Le groupe
                                </a>
                            </li>
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/blog">
                                    Blog
                                </a>
                            </li>
                            {/*<li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/devenir-partenaire">
                                    Devenir partenaire
                                </a>
                            </li>*/}
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="border-bottom-mobile-footer"></div>
                </div>


                <div>
                    <div className="text-sm">
                        <h2 className="font-semibold text-neutral-700 white:text-neutral-200">
                        </h2>
                        <ul className="mt-5 space-y-4">
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/mentions-legales">
                                    Mentions légales
                                </a>
                            </li>
                            <li className="uppercase">
                                <a
                                    className="item-footer text-neutral-6000 white:text-neutral-300 hover:text-black white:hover:text-white"
                                    href="/donnees-personnelles">
                                    Données Personnelles
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="border-bottom-mobile-footer"></div>
                </div>





            </div>
        </div>
    );
};

export default Footer;
