import {motion, useAnimation} from "framer-motion";
import { useEffect} from "react";
import { useInView} from "react-intersection-observer";

const GroupePresentation = () => {


    const container = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);

    return(
        <>
            <div
                className="container-prog container-accompagnement">
                <div  className="container-txt-accompagnement">
                    <h2 >KOTIMO, Votre projet à vos côtés</h2>
                    <p>
                        La recherche d’un bien immobilier peut actuellement devenir un véritable parcours du combattant. Entre chercher un bien qui coche toutes les cases de sa liste d’envie, faire confiance à un promoteur immobilier fiable et trouver la pépite vous correspondant, Il est difficile de se retrouver seul sur le marché.<br/><br/> Il existe en effet une multitude d’acteurs, de logements, de programmes et de possibilités de financement... On peut facilement se sentir désorienté et baisser les bras. Si on vous disait que nous avons LA solution : un accompagnement sur-mesure pour votre acquisition. “Kotimo Votre projet à vos cotés”.
                    </p>
                    <div className="container-buttons">
                            <a className="button-v" href="/blog">EN SAVOIR PLUS</a>
                    </div>
                </div>
                <div className="container-image">
                    <img alt='equipe qui fait une réunion' src={ require('../../images/kotimo-prez.jpg') } />
                </div>
            </div>

            <motion.div
                ref={ref}
                variants={container}
                initial="hidden"
                animate={control}
                className="naissance-kotimo"
            >
                <h2>COMMENT EST NÉ KOTIMO ?</h2>
                <p> Kotimo, pensé et réfléchi pour accompagner ses clients dans l’achat d’un bien immobilier neuf, se base sur la forte expérience humaine de ses dirigeants. En effet, il peut être difficile pour un particulier, n’étant pas du tout dans le secteur, de comprendre toutes les astuces, les aides au financement ou bien encore toutes les subtilités des contrats de vente.<br/><br/> Kotimo, nouvelle marque, nouveau partenaire d’aide et d’accompagnement pour vous faciliter la recherche de votre bien immobilier neuf ! À l’écoute, nous souhaitons aujourd’hui vous proposer un suivi personnalisé dans toutes les étapes de votre acquisition. Notre objectif premier, répondre au maximum à vos besoins et vous trouver votre perle rare. Proche de vos préoccupations, nous sommes conscients que cette future acquisition est un véritable projet de vie. </p>
            </motion.div>
        </>
    )
}
export default GroupePresentation