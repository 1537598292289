import {Link} from "react-router-dom";

const Logo = ({isTop}) => {
    return(
        <a href="/"
            className={`ttnc-logo inline-block text-primary-6000`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="200.586" height="59.55" viewBox="0 0 258.586 59.55" className={isTop ? "bigLogo " : "notOnTop littleLogo"}>
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_58" data-name="Rectangle 58" width="258.586" height="59.55" fill="none"/>
                    </clipPath>
                </defs>
                <g id="Logo" transform="translate(-40 -35.341)">
                    <path id="Tracé_64" data-name="Tracé 64" d="M0,0V59.55H13.784V44l10.256-9.7,20.4,25.253H61.2L34.3,25.364,58.888.662H42.346L13.784,27.129V0Z" transform="translate(40 35.34)" fill="#3e256b"/>
                    <g id="Groupe_11" data-name="Groupe 11" transform="translate(40 35.341)">
                        <g id="Groupe_10" data-name="Groupe 10" clip-path="url(#clip-path)">
                            <path id="Tracé_65" data-name="Tracé 65" d="M237.858,59.674c-.149,0-.3,0-.443-.008-4.2-.232-7.546-2.422-9.961-6.508a16.28,16.28,0,0,1-2.119-8.456V1.891h6.392V21.555h9.683v5.612h-9.647V43.821c-.142,4.118,1.193,7.181,4.083,9.384,2.206,1.594,5,.673,6.962-.381l.574-.31.221.614c.665,1.848,1.212,3.3,1.624,4.32l.215.531-.535.2a20.1,20.1,0,0,1-7.048,1.49" transform="translate(-120.092 -1.008)" fill="#a4855e"/>
                            <path id="Tracé_66" data-name="Tracé 66" d="M286.224,58.7H279.87V16.877h6.354Zm-3.2-48.786a4.674,4.674,0,0,1-3.443-1.448,4.766,4.766,0,0,1-1.423-3.454,4.707,4.707,0,0,1,1.426-3.44A4.708,4.708,0,0,1,283.029.144a4.772,4.772,0,0,1,3.454,1.423,4.677,4.677,0,0,1,1.447,3.444,4.735,4.735,0,0,1-1.444,3.458,4.737,4.737,0,0,1-3.458,1.445" transform="translate(-148.248 -0.077)" fill="#a4855e"/>
                            <path id="Tracé_67" data-name="Tracé 67" d="M378.022,76.6h-6.465V53.819c0-4.266-.3-7.22-.891-8.78a7.59,7.59,0,0,0-2.977-3.682,9.13,9.13,0,0,0-5.143-1.408c-4.44.115-7.415,2.023-9.835,5.61-.986,1.462-2.315,4.679-2.4,9.885V76.6h-6.354V55.19c0-4.768-.522-8.033-1.55-9.7a13.533,13.533,0,0,0-3.738-4.062,9.129,9.129,0,0,0-5.318-1.475,10.027,10.027,0,0,0-5.926,1.751,11.6,11.6,0,0,0-3.9,5.3c-.956,2.377-1.342,6.338-1.147,11.773V76.6h-6.318V53.781c0-6.193,1.954-10.214,3.593-12.5,3.731-5.2,8.217-7.67,13.677-7.542a16.474,16.474,0,0,1,14.068,8.881,17.845,17.845,0,0,1,3.1-4.183c2.03-2.07,5.718-4.562,11.781-4.7a16.724,16.724,0,0,1,8.406,2.182,12.339,12.339,0,0,1,5.724,6.235c1.074,2.616,1.619,6.54,1.619,11.66Z" transform="translate(-168.442 -17.98)" fill="#a4855e"/>
                            <path id="Tracé_68" data-name="Tracé 68" d="M482.286,75.543A20.527,20.527,0,0,1,466.609,69a22.395,22.395,0,0,1-.322-30.308,21.8,21.8,0,0,1,32-.04,21.95,21.95,0,0,1,5.749,15.108A21.726,21.726,0,0,1,497.945,69a20.543,20.543,0,0,1-15.659,6.541" transform="translate(-245.446 -16.884)" fill="#a4855e"/>
                            <path id="Tracé_69" data-name="Tracé 69" d="M141.743,75.544A20.526,20.526,0,0,1,126.065,69a22.4,22.4,0,0,1-.322-30.308,21.8,21.8,0,0,1,32-.04,21.95,21.95,0,0,1,5.749,15.108A21.726,21.726,0,0,1,157.4,69a20.543,20.543,0,0,1-15.659,6.541m0-37.62a14.534,14.534,0,0,0-10.764,4.652,15.66,15.66,0,0,0-4.516,11.26,16.248,16.248,0,0,0,2.062,7.982,14.482,14.482,0,0,0,5.556,5.683,15.879,15.879,0,0,0,15.379,0,14.4,14.4,0,0,0,5.518-5.682,16.373,16.373,0,0,0,2.046-7.983,15.656,15.656,0,0,0-4.517-11.26,14.535,14.535,0,0,0-10.764-4.652" transform="translate(-63.953 -16.884)" fill="#a4855e"/>
                            <path id="Tracé_70" data-name="Tracé 70" d="M491.138,46.94,488.18,49.4l-2.98-2.48L474.245,56.04V69.687h3.625v4.919h26.536V57.982ZM475.313,68.619V56.54l9.887-8.228,2.145,1.785-9.475,7.886V68.619Zm13.675-17.155,6.1,5.076V68.619H479.971V58.968ZM502.3,72.5H479.971V69.687h16.184V56.04l-6.333-5.271,1.315-1.094L502.3,58.968Z" transform="translate(-252.75 -25.007)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </svg>
        </a>
    )
}
export default Logo