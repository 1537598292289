import React from 'react';
import Select from 'react-select';

import selectOptions from "../../utils/selectOption.json";
import controlList from "../../utils/controls.json";

class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: 0,
            details: {
                numberOfMonths: "",
                interestRate: "",
                monthlyPaymentAmount: "",
                loanAmount: "",
                termLength: "",
                tauxAssurance: ""
            },
            selectedOption: "FM",
            error: false,
            resultLabel: "Mortgage"
        };
        this.onSubmitHandle = this.onSubmitHandle.bind(this);
        this.onChangeHandle = this.onChangeHandle.bind(this);
    }

    onSubmitHandle(e) {
        e.preventDefault();
        this.calculateAndSetResults();
    }


    setError() {
        this.setState({ error: true });
    }

    setResult(result) {
        this.setState({ result });
    }

    calculateAndSetResults() {
        /* eslint no-eval: 0 */
        let result = 0;
        let loanAmount;
        let interestRate;
        let numberOfMonths;
        let monthlyPaymentAmount;
        let tauxAssurance;
        switch (this.state.selectedOption) {
            case "FM":
                if (
                    this.state.details.loanAmount.trim() !== "" &&
                    this.state.details.interestRate.trim() !== "" &&
                    this.state.details.termLength.trim() !== ""
                ) {
                    loanAmount = eval(this.state.details.loanAmount);
                    interestRate = eval(this.state.details.interestRate / 1200);
                    numberOfMonths = eval(this.state.details.termLength * 12);
                    result =
                        Number(eval(loanAmount * interestRate) /
                            (1 - Math.pow(1 + interestRate, numberOfMonths * -1))).toFixed(0);
                    result = `${result}`;
                } else {
                    this.setError();
                }
                break;

            case "FLA":
                monthlyPaymentAmount = this.state.details.monthlyPaymentAmount;
                numberOfMonths = this.state.details.numberOfMonths;
                interestRate = this.state.details.interestRate;
                if (
                    monthlyPaymentAmount !== "" &&
                    numberOfMonths !== "" &&
                    interestRate !== ""
                ) {
                    interestRate = interestRate / 1200;
                    result = eval(
                        (monthlyPaymentAmount / interestRate) *
                        (1 - 1 / Math.pow(1 + interestRate, numberOfMonths))
                    ).toFixed(2);
                    result = `$${result}`;
                } else {
                    this.setError();
                }
                break;

            case "FMP":
                loanAmount = this.state.details.loanAmount;
                numberOfMonths = this.state.details.numberOfMonths;
                interestRate = this.state.details.interestRate;
                tauxAssurance = this.state.details.tauxAssurance;
                if (
                    loanAmount !== "" &&
                    numberOfMonths !== "" &&
                    (interestRate !== "") & (tauxAssurance !== "")
                ) {
                    interestRate = interestRate / 1200;
                    result = Number(eval(
                        (loanAmount *
                            interestRate *
                            Math.pow(1 + interestRate, numberOfMonths)) /
                        (Math.pow(1 + interestRate, numberOfMonths) - 1)
                    )).toFixed(2);

                    result = `$${result}`;
                } else {
                    this.setError();
                }
                break;

            case "FIR":
                loanAmount = this.state.details.loanAmount;
                numberOfMonths = this.state.details.numberOfMonths;
                monthlyPaymentAmount = this.state.details.monthlyPaymentAmount;
                if (
                    monthlyPaymentAmount !== "" &&
                    numberOfMonths !== "" &&
                    loanAmount !== ""
                ) {
                    const F = (x) =>
                        (loanAmount * x * Math.pow(1 + x, numberOfMonths)) /
                        (Math.pow(1 + x, numberOfMonths) - 1) -
                        monthlyPaymentAmount;
                    const F_prime = (x) =>
                        (loanAmount *
                            Math.pow(x + 1, numberOfMonths - 1) *
                            (x * Math.pow(x + 1, numberOfMonths) +
                                Math.pow(x + 1, numberOfMonths) -
                                numberOfMonths * x -
                                x -
                                1)) /
                        Math.pow(Math.pow(x + 1, numberOfMonths) - 1, 2);

                    const guess =
                        1 + ((monthlyPaymentAmount * numberOfMonths) / loanAmount - 1) / 12;

                    let x = guess;

                    while (Math.abs(F(x)) > 1e-6) {
                        x = x - F(x) / F_prime(x);
                    }
                    x = (12 * x * 100).toFixed(2);
                    result = `${x}%`;
                } else {
                    this.setError();
                }
                break;

            case "FNM":
                loanAmount = this.state.details.loanAmount;
                monthlyPaymentAmount = this.state.details.monthlyPaymentAmount;
                interestRate = this.state.details.interestRate;
                if (
                    loanAmount !== "" &&
                    monthlyPaymentAmount !== "" &&
                    interestRate !== ""
                ) {
                    interestRate = interestRate / 1200;
                    result = eval(
                        Math.log(
                            monthlyPaymentAmount /
                            interestRate /
                            (monthlyPaymentAmount / interestRate - loanAmount)
                        ) / Math.log(1 + interestRate)
                    ).toFixed(2);
                } else {
                    this.setError();
                }
                break;
            default:
                break;
        }

        this.setResult(result);
    }

    onChangeHandle({ value, resultLabel }) {
        this.setState({ selectedOption: value, resultLabel: resultLabel });
    }

    onInputChange(e, type) {
        this.setState((state) => {
            state.details[type] =
                e.target.value.trim() >= 0 ? e.target.value.trim() : "";
            state.result = 0;
            state.error = false;
            return state;
        });
    }

    Error() {
        return (
            <div
                className="errorWrap align-center"
                style={{
                    display: (this.state.error && "flex") || "none",
                    color: "red"
                }}
            >
                <em>Veuillez renseigner des éléments pour le calcul</em>
            </div>
        );
    }


    Results() {
        return (
            <div
                className="resultBlock"
            >
                <span className="resultText">
          <span className="text-result-calc">{this.state.result}</span>
        </span>
            </div>
        );
    }


    followButton(){
        const rangeInputs = document.querySelectorAll('input[type="range"]')

        function handleInputChange(e) {
            let target = e.target
            if (e.target.type !== 'range') {
                target = document.getElementById('range')
            }
            const min = target.min
            const max = target.max
            const val = target.value

            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        }

        rangeInputs.forEach(input => {
            input.addEventListener('input', handleInputChange)
        })
    }
    renderInputControls() {
        const list = controlList[this.state.selectedOption];



        return(
            <div className="container-calcul">

                <React.Fragment>
                    <div className="inputs-calcu">
                        {list?.map((item) => {
                            return (
                                <div className="input-control flex_1" key={item.event}>
                                    <div className="input-response-solo">
                                        <label className="dark:text-white">{item.label}</label>
                                        <output className="dark:text-white" id="rangevalue">{this.state.details[item.event]} {item.symbole}</output>
                                    </div>
                                    <input

                                        oninput="rangevalue.value=value"
                                        className="input_range"
                                        type="range"
                                        step={item.step}
                                        min={item.min}
                                        max={item.max}
                                        onChange={(e) => this.onInputChange(e, item.event)}
                                        value={ this.state.details[item.event]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>

            </div>
        )
    }

    render() {
        return (
            <div className=" dark:bg-black text-black dark:text-white container-calcu">
                <h2 className="mb-7 text-xl text-center uppercase">Calculez le coût mensuel de votre achat</h2>
                <div className="wrapperInner">
                    <h2 className="heading disable">Simple React Loan & Mortgage Calculator</h2>
                    <div className="container-champs-calcu">
                        <form onSubmit={(e) => this.onSubmitHandle(e)}>
                            <div className="select-control disable">
                                <label>Please Select Type</label>
                                <Select
                                    options={selectOptions}
                                    onChange={(obj) => this.onChangeHandle(obj)}
                                    isSearchable={false}
                                    isClearable={false}
                                    defaultValue={selectOptions[0]}
                                />
                            </div>
                            <div className="form__input--control">
                                {this.renderInputControls()}
                            </div>
                            <div className="buttonsControlWrap mt-6 flex justify-center direction-column align-center">
                                <button
                                    type="submit"
                                    className="text-[#A08155] button-v submitBtn "
                                    data-event_tag="CalculateBtnClick"
                                    data-event_action="UserClick"
                                >
                                    Calculer
                                </button>
                            </div>
                        </form>
                        <div className="container-response">
                            <p>Votre mensualité sera de:<br/>
                                {this.Results()}  €/mois</p>

                        </div>

                    </div>

                    {this.Error()}

                </div>
                <div className="flex justify-center mt-8">
                    <a className="button-v" href="/video">MON PROJET</a>
                </div>

            </div>
        );
    }
}


export { Calculator }