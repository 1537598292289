import { useState, useEffect} from "react";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";


const AccompagnementDeux = () => {

    const container = {
        hidden: { opacity: 0},
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const card = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
    }, [control, inView]);

    return(
        <div
            className={`nc-SectionHowItWork container-3parts`}
            data-nc-id="SectionHowItWork"
        >
            <h2 className="text-xl text-center uppercase">
                Un accompagnement sur-mesure
            </h2>
            <motion.ul
                ref={ref}
                variants={container}
                initial="hidden"
                animate={control}
                className="mt-20 relative grid md:grid-cols-3 gap-20">



                    <motion.li
                        variants={card}
                        className="relative flex flex-col items-center max-w-xs mx-auto">
                        <div className="text-center">
                            <h3 className="text-h3 font-semibold">Transparence et accompagnement</h3>
                            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
               Une équipe d’experts impliqués pour lever vos doutes, répondre à vos interrogations et vous guider tout au long de votre projet.
              </span>
                        </div>
                    </motion.li>


                <motion.li
                    variants={card}
                    className="relative flex flex-col items-center max-w-xs mx-auto">
                    <div className="text-center">
                        <h3 className="text-h3 font-semibold">Réactivité et pertinence</h3>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                            Nous vous proposons des programmes adaptés à vos exigences ainsi que des conseils clairs et précis pour un achat sans surprise.
                        </span>
                    </div>
                    <a href="/video" className="mt-7 button-v">MON PROJET</a>
                </motion.li>


                <motion.li
                    variants={card}
                    className="relative flex flex-col items-center max-w-xs mx-auto">
                    <div className="text-center">
                        <h3 className="text-h3 font-semibold">Professionnalisme et confiance</h3>
                        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                           De l’étude financière jusqu’à la livraison, nos experts vous guident et réalisent un suivi complet de votre projet.
                        </span>
                    </div>
                </motion.li>
            </motion.ul>
        </div>
    )
}

export default AccompagnementDeux